@if ({ pendingOrgSwitch: pendingOrgSwitch$ | async, pendingOrgRegistration: pendingOrgRegistration$ | async }; as data) {
  <div class="mat-app-background">
    <div class="header-wrapper">
      <app-navbar
        [isSidenavOpen]="isExpanded"
        [titleExtension]="routingState.isMigrationPage() ? ('migration.landing-page.navbar-title' | translate) : undefined"
        [hideNavbarElements]="routingState.isMigrationPage()"
        (sidenavToggleEvent)="getActionFromNavbar($event)"
      >
      </app-navbar>
    </div>
    @if (!routingState.isMigrationPage()) {
      <cde-sidenav [orgRegistered]="orgRegistered" [isExpanded]="isExpanded" (clickedEvent)="handleClickedEvent($event)">
        @if (!data.pendingOrgSwitch && !data.pendingOrgRegistration) {
          <div class="h-full overflow-hidden">
            <div #toastsWrapper class="relative">
              @if(!validatedOrg && orgRegistered && !hidevalidatedOrgAlertBanner){
                <cde-alert-banner
                  [inlineAlertBanner]="true"
                  [bannerType]="'info'"
                  [title]="'alert_banner.unverified_org.title' | translate"
                  [description]="'alert_banner.unverified_org.description' | translate"
                  [inlineText]="true"
                  (closeButtonClicked)="closeUnverifiedOrgAlertBanner()"
                  data-testid="unverified-org-banner"
                ></cde-alert-banner>
              }
              @for (maintenanceToast of maintenanceToasts; track maintenanceToast; let i = $index) {
                <cde-alert-banner
                  [inlineAlertBanner]="true"
                  [bannerType]="maintenanceToast.type"
                  [title]="maintenanceToast.title"
                  [description]="maintenanceToast.text"
                  [inlineText]="true"
                  (closeButtonClicked)="closeAlertWarning(i)"
                  data-testid="maintenance-banner"
                ></cde-alert-banner>
              }
            </div>
            <div #contentWrapper class="overflow-auto relative h-full">
              <router-outlet></router-outlet>
            </div>
          </div>
        } @else {
          <div class="flex items-center justify-center">
            <app-spinner></app-spinner>
          </div>
        }
      </cde-sidenav>
    } @else {
      <div class="migration-router-outlet-container">
        <router-outlet></router-outlet>
      </div>
    }
  </div>
  @if (orgChanging) {
    <div class="org-loading-backdrop">
      <span class="loading-msg" data-testid="change-organisation-message">{{ 'global.changing_organization' | translate }}</span>
    </div>
  }
}
