import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError, finalize } from 'rxjs/operators';

import * as DealerActions from './dealer.actions';
import * as RootState from '../';
import { DealerService } from '@app/shared/services/dealer.service';
import { IGetDealerRequest } from '@app/shared/models/dealer.model';

@Injectable()
export class DealerEffects {

  getDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealerActions.GET_DEALER),
      map((action: DealerActions.GetDealer) => action.request),
      mergeMap((request: IGetDealerRequest) =>
        this.dealerService.getDealer(request.dealerId, request.language, request.dealerLocatorId).pipe(
          map((response) => new DealerActions.GetDealerSuccess(response)),
          catchError((error) => of(new DealerActions.GetDealerFailure(request.dealerId, error))),
          finalize(() => this.store.dispatch(new DealerActions.GetDealerComplete()))
        )
      )
    )
  );

  getDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealerActions.GET_DEALERS),
      mergeMap((action: DealerActions.GetDealers) => {
        const actions = action.dealers.map(dealer => new DealerActions.GetDealer({
          dealerId: dealer.dealerId,
          language: action.language,
          dealerLocatorId: dealer.dealerLocatorId
        }));
        return actions;
      })
    )
  );

  getDealerFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealerActions.GET_DEALER_FILTERS),
      mergeMap(() =>
        this.dealerService.getDealerFilters().pipe(
          map((response) => new DealerActions.GetDealerFiltersSuccess(response)),
          catchError((error) => of(new DealerActions.GetDealerFiltersFailure(error))),
          finalize(() => this.store.dispatch(new DealerActions.GetDealerFiltersComplete()))
        )
      )
    )
  );

  searchDealersByAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DealerActions.SEARCH_DEALERS_BY_ADDRESS),
      mergeMap((action: DealerActions.SearchDealersByAddress) =>
        this.dealerService.getDealersByAddress(action.request, action.language).pipe(
          map((response) => new DealerActions.SearchDealersByAddressSuccess(response)),
          catchError((error) => of(new DealerActions.SearchDealersByAddressFailure(error))),
          finalize(() => this.store.dispatch(new DealerActions.SearchDealersByAddressComplete()))
        )
      )
    )
  );

  constructor(
    private store: Store<RootState.IState>, // tslint:disable-line:no-any
    private actions$: Actions,
    private dealerService: DealerService,
  ) {}
}
